<template>
  <div class="modal" ref="publishForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span class="screen-name">
              <i class="fa fa-globe" v-if="screen.public"></i>
              <i class="fa fa-briefcase" v-else></i>
              <span>
                {{
                  isNew
                    ? `${$t("publish")} ${$tc("new", 2)} ${$tc("screen")}`
                    : `${$t("publish")} [#${
                        screen.id + (panelName ? "/" + panelName : "")
                      }]`
                }}
              </span>
            </span>
          </h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-error" role="alert" v-if="screen.public">
            {{ $t("this_screen_cannot_be_overwritten") }}
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form role="form" @submit.prevent="isValid && !busy && onConfirm()">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  :placeholder="$t('name')"
                  ref="name"
                  data-testid="name"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("description") }}</label>
                <textarea
                  rows="2"
                  cols="80"
                  type="text"
                  :maxlength="maxLengthDescription"
                  class="form-control"
                  :placeholder="$t('description')"
                  v-model="form.description"
                  data-testid="description"
                  style="min-height: 34px"
                ></textarea>
                <i class="small">
                  {{ $t("remaining_characters") }}:
                  {{ descriptionRemainingCharacters }}
                </i>
              </div>
            </div>
            <!-- <div class="row" v-if="!isNew"> -->
            <div class="row">
              <div
                class="form-group col-sm-3"
                :class="{ 'has-error': hasRevisionCodeError }"
              >
                <label for="">{{ $t("version") }}</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="v1.00"
                  ref="version"
                  v-model="form.revision_code"
                  data-testid="revision-code"
                  :title="
                    !isRevisionCodeValid && form.revision_code
                      ? $t('same_revision_code_error')
                      : ''
                  "
                />
                <div
                  class="small text-center"
                  style="margin-top: 5px; color: #808080"
                  v-if="!isNew && lastPublishedScreen"
                >
                  <div :title="lastPublishedScreen.name">
                    {{ $t("last_version") }}:
                    {{ lastPublishedScreen.revision_code || "" }}
                  </div>
                  <div>
                    {{ updatedAt }}
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-9">
                <label for="">{{ $t("release_notes") }}</label>
                <textarea
                  rows="3"
                  cols="80"
                  type="text"
                  class="form-control"
                  :maxlength="maxLengthReleaseNotes"
                  :placeholder="$t('release_notes_placeholder')"
                  v-model="form.revision_comment"
                  data-testid="revision-comment"
                  style="min-height: 34px"
                ></textarea>
                <i class="small">
                  {{ $t("remaining_characters") }}:
                  {{ releaseNotesRemainingCharacters }}
                </i>
              </div>
            </div>
          </form>
          <div class="box box-warning" v-if="outOfDate">
            <div class="box-header with-border bg-warning">
              <i class="fa fa-warning"></i>
              <h3 class="box-title">{{ $t("attention") }}</h3>
            </div>
            <div class="box-body bg-warning">
              <p
                v-html="
                  $t('old_version_alert', {
                    version:
                      screen && screen.revision_code
                        ? `(${screen.revision_code})`
                        : '?'
                  })
                "
              ></p>
              <div class="form-group">
                <label class="checkbox-inline">
                  <input type="checkbox" v-model="overwrite" />
                  {{ $t("titles.overwrite_existing_file") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-right">
          <button
            class="pull-left btn btn-default"
            data-testid="close"
            v-on:click.stop="onCancel"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="btn btn-primary"
            v-on:click.stop="onConfirm"
            v-bind:disabled="!isValid || busy"
            data-testid="save"
          >
            {{ $t("confirm") }}
          </button>
        </div>
        <div class="overlay overlay-local" v-if="busy">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenService from "@/services/screen";
import MixinAlert from "@/project/mixin-alert.js";
import DashboardPublisher from "@/components/editor/dashboard-publisher.vue";
// import Alert from "@/components/widgets/alert";

const validateTemplate = (template) => {
  if (!template || !template?.layout) return false;
  let rows = template?.layout || [];
  if (!rows.length) return false;
  for (var ir in rows) {
    let cols = rows[ir] || [];
    if (!cols.length) return false;
    for (var ic in cols) {
      let lst = cols[ic]?.panels || [];
      if (!lst.length) return false;
      for (var ip in lst || []) {
        if (
          !lst[ip] ||
          !(template?.panels || []).some(({ name }) => name == lst[ip])
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

const invalidTemplateAlert = (vm) => {
  vm.$swal({
    title: vm.$t("action_not_allowed"),
    text: vm.$t("there_are_invalid_panels_on_this_screen"),
    icon: "warning"
  });
  return;
};

export { validateTemplate, invalidTemplateAlert };

const MAX_SIZE_RELEASE_NOTES = 1000;
const MAX_SIZE_DESCRIPTION = 200;

export default {
  name: "PublishForm",
  extends: DashboardPublisher,
  mixins: [MixinAlert],
  components: {
    // Alert
  },
  props: {
    panelName: {
      type: String,
      required: false,
      default: () => ""
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        description: "",
        revision_code: "1.00",
        revision_comment: "",
        public: false
      },
      overwrite: false,
      lastPublishedScreen: null
    };
  },
  computed: {
    isNew() {
      return parseInt(this.screenId) <= 0;
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId) ?? {};
    },
    updatedAt() {
      let ts = this?.lastPublishedScreen?.updated_at || "";
      return ts ? this.$dt.format(ts) : "";
    },
    draft() {
      return this.$store.getters["dashboard/draft"];
    },
    isValid() {
      return this.draft &&
        this.draft.screenId &&
        this.draft.template &&
        this.isRevisionCodeValid &&
        this.form.name &&
        (!this.outOfDate || this.overwrite)
        ? true
        : false;
    },
    isRevisionCodeValid() {
      return (
        (this.form.revision_code &&
          this.form.revision_code != this.screen?.revision_code) ||
        this.isNew
      );
    },
    hasRevisionCodeError() {
      return (
        !this.isRevisionCodeValid &&
        document.activeElement == this.$refs.version
      );
    },
    ETag() {
      let draft = this.$store.getters["dashboard/draft"] || null;
      return (draft && draft?.template?.draft?.etag) || null;
    },
    outOfDate() {
      return this.lastPublishedScreen && this.ETag && this.ETag != this.lastPublishedScreen.etag;
    },
    maxLengthReleaseNotes() {
      return MAX_SIZE_RELEASE_NOTES;
    },
    maxLengthDescription() {
      return MAX_SIZE_DESCRIPTION;
    },
    releaseNotes() {
      return this.form.revision_comment;
    },
    releaseNotesRemainingCharacters() {
      return Math.max(0, MAX_SIZE_RELEASE_NOTES - this.releaseNotes.length);
    },
    description() {
      return this.form.description;
    },
    descriptionRemainingCharacters() {
      return Math.max(0, MAX_SIZE_DESCRIPTION - this.description.length);
    }
  },
  methods: {
    onCancel() {
      this.close();
    },
    async publish() {
      let self = this;
      if (!this.isValid) return;
      let reference_connectors_ids;
      if (this.screenRefMap) {
        reference_connectors_ids = Object.keys(this.screenRefMap).map(
          (key) => this.screenRefMap[key]
        );
      }
      let content = await this.content();
      if (!content) return;
      //=====================================================
      let payload = JSON.parse(JSON.stringify(this.form));
      let processAreaId = null;
      let etag = null;
      // drafts object contains properties that will not be published
      // therefore it will be deleted after proper distribution
      if ("draft" in content) {
        processAreaId = content.draft.processAreaId;
        etag = content.draft.etag;
        if (content.draft.tags) {
          payload.portal_data = payload.portal_data || {};
          payload.portal_data.tags = JSON.parse(
            JSON.stringify(content.draft.tags)
          );
        }
        delete content["draft"];
      }
      payload.content = content;
      payload.contract_id = this.contractId;
      if (reference_connectors_ids) {
        payload.reference_connectors_ids = reference_connectors_ids;
      }
      payload.process_area_id = processAreaId;
      if (this.isNew) {
        delete payload.id;
        payload.public = false;
      } else {
        if (!this.screen.public && this.screen.id) {
          payload.id = this.screen.id;
        }
      }
      if (etag) {
        payload.etag = (this.outOfDate && this.overwrite) ? this.lastPublishedScreen.etag : etag;
      }
      self.busy = true;
      let ret = await this.srv.save(payload);
      self.busy = false;
      if (self.validateSaveResponse(ret)) {
        self.onSuccess(ret, payload.content);
      } else {
        self.onError();
      }
    },
    onConfirm() {
      this.publish();
    },
    async onSuccess(ret, template) {
      this.$store.commit("dashboard/SET_SCREEN", ret);
      this.$store.commit("dashboard/SET_TEMPLATE", {
        id: ret.id,
        data: template
      });
      let draft = this.$store.getters["dashboard/draft"];
      if (this.isNew) {
        this.$store.dispatch("dashboard/setParent", { dbKey: 'tree_screen', id: ret.id, oldId: this.screenId });
        this.removeScreen(); // remove all draft content and screen
      } else {
        // this.removeDraft(); // remove only the draft content
      }
      // since user might be publishing from panel editor - do not remove draft yet
      if (draft && draft.template) {
        let tpl = JSON.parse(JSON.stringify(draft.template));
        if (tpl?.draft?.etag) {
          tpl.draft.etag = "";
        }
        if (ret?.portal_data?.tags) {
          tpl.draft.tags = JSON.parse(JSON.stringify(ret?.portal_data?.tags));
        }
        tpl.title = ret.name;
        this.$store.commit("dashboard/SAVE_DRAFT", {
          screenId: ret.id,
          template: tpl
        });
      }
      this.showAlert(() => {
        this.close();
      });
      this.$store.commit("dashboard/IS_READY", false);
      this.$emit("saved", ret);
      this.$nextTick(() => {
        this.$store.commit("dashboard/IS_READY", true);
        this.$root.$emit("dashboard:editor", { action: "editDashboard" });
      });
    },
    onError() {
      this.showAlert();
    },
    close() {
      // this.$emit("close");
      $(this.$refs.publishForm).modal("hide");
    },
    open() {
      this.$store.dispatch("dashboard/sortPanels");
      $(this.$refs.publishForm).modal("show");
    },
    createModal() {
      let self = this;
      $(this.$refs.publishForm)
        .on("shown.bs.modal", () => {
          self.$root.$emit("editor.keyboard:stop");
          self.$nextTick(() => {
            if (self.isNew) self.$refs.name.focus();
            else self.$refs.version.focus();
          });
          self.$emit("show");
        })
        .on("hidden.bs.modal", () => {
          self.$root.$emit("editor.keyboard:start");
          self.$emit("close");
        });
      this.open();
    }
  },
  mounted() {
    let title = this?.draft?.template?.title || "";
    if (title.toUpperCase().indexOf("UNTITLED") >= 0) title = "";
    this.form.name = title ? this.$t(title) : this.screen.name || "";
    this.form.description = this.screen.description;
    // this.form.revision_code = this.isNew
    //   ? "1.00"
    //   : (parseFloat(this?.screen?.revision_code || 1) + 0.01).toFixed(2);
    this.form.revision_comment = this.isNew ? "" : ""; // "TODO: add default string for first version"
    this.createModal();
  },
  created() {
    this.form.revision_code = this.isNew
      ? "1.00"
      : (parseFloat(this?.screen?.revision_code || 1) + 0.01).toFixed(2);
    if (this.screen.id > 0) {
      this.srv.get(this.screen.id).then((screen) => {
        this.lastPublishedScreen = screen;
        this.form.revision_code = this.isNew
          ? "1.00"
          : (parseFloat(this?.lastPublishedScreen?.revision_code || 1) + 0.01).toFixed(2);
      });
    }

  },
  beforeCreate() {
    this.srv = new ScreenService();
  },
  beforeDestroy() {
    this.srv = null;
  }
};
</script>

<style scoped>
.overlay-local {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2em;
  text-align: center;
  color: #607d8b;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
}

.overlay-local .fa-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

span.screen-name i {
  color: gray;
}
span.screen-name span {
  margin-left: 3px;
}
.modal-content {
  border-radius: 6px;
}
</style>
