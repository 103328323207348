var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FileExplorer',{ref:"fileExplorer",attrs:{"items":_vm.activeList,"modal":_vm.modal,"multiSelection":_vm.multiSelection,"title":`${_vm.maxResult} ${_vm.$tc('screen', _vm.maxResult > 1 ? 2 : 1)}`,"maxResult":_vm.maxResult,"discarded":_vm.discarded,"dbKey":"tree_screen"},on:{"change":function($event){_vm.tree = $event},"open":_vm.onOpen,"close":_vm.onClose,"drop":_vm.resetSearch,"beforeRestore":_vm.resetSearch,"sidebarResize":_vm.onSidebarResize,"move":_vm.onItemsMoved},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('div',{staticStyle:{"padding":"15px 10px 5px 10px","position":"relative"}},[(_vm.items && _vm.items.length && _vm.$refs.stbl)?_c('SearchableTableQueryInput',{ref:"query",attrs:{"createCommand":_vm.$refs.stbl.createCommand},on:{"create":_vm.create},scopedSlots:_vm._u([{key:"customFilters",fn:function(){return [_c('section',[(_vm.recentList.length)?_c('div',_vm._l((_vm.recentList),function(screen,ix){return _c('span',{key:screen.id},[_c('a',{staticClass:"btn btn-xs",attrs:{"href":"javascript:void","title":`ID: ${screen.id}\nLast Update: ${screen.ts}\nVersion: ${screen.revision_code}`},on:{"click":function($event){return _vm.onCommand('select', screen)}}},[_vm._v(" "+_vm._s(_vm.$utils.proper(screen.name))+" ")]),(ix != _vm.recentList.length - 1)?_c('span',{staticClass:"sep"},[_vm._v(" | ")]):_vm._e()])}),0):_vm._e()])]},proxy:true},{key:"extraButtons",fn:function(){return [(_vm.multiSelection.values.length)?[(_vm.trashCanSelected)?_c('button',{staticClass:"btn btn-default",attrs:{"title":_vm.$t('restore')},on:{"click":function($event){return _vm.onCommand('command', {
                  name: 'restore',
                  target: _vm.multiSelection.values
                })}}},[_c('i',{staticClass:"fa fa-undo"})]):_vm._e(),(_vm.canRemoveItems)?_c('button',{staticClass:"btn btn-default",attrs:{"title":`${
                _vm.trashCanSelected ? _vm.$tc('remove_permanently', 1) : _vm.$t('remove')
              }\n${_vm.$t('mass_remove')}`},on:{"click":function($event){return _vm.onCommand('command', {
                  name: 'remove',
                  target: _vm.multiSelection.values
                })}}},[_c('i',{staticClass:"fa fa-trash-o"})]):_vm._e()]:_vm._e()]},proxy:true},{key:"statistics",fn:function(){return [_c('ResourceStatistics',{attrs:{"resource":"screen","total":_vm.nPrivateItems,"showing":(_vm.$refs.stbl &&
                _vm.$refs.stbl.itemList &&
                _vm.$refs.stbl.itemList.length) ||
              0,"icon":"fa fa-desktop"}})]},proxy:true}],null,false,2452063296),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e(),(_vm.msgBoard)?_c('div',{staticClass:"alert alert-default msg-board"},[_vm._v(" "+_vm._s(_vm.msgBoard)+" ")]):_vm._e()],1)]},proxy:true},{key:"files",fn:function(){return [_c('SearchableTable',{ref:"stbl",staticClass:"table-container fade-in",class:_vm.$refs.fileExplorer && _vm.$refs.fileExplorer.ready
          ? 'easy-show'
          : 'easy-hide',style:({
        'margin-top': _vm.$refs.stbl && _vm.$refs.stbl.showPagination ? '-6px' : '0'
      }),attrs:{"show":_vm.$refs.fileExplorer && _vm.$refs.fileExplorer.ready,"items":_vm.filteredItems,"fields":_vm.fields,"commands":_vm.commands,"pagination":_vm.modal ? false : _vm.pagination,"maxResult":_vm.maxResult,"multiColumnOrder":false,"clientSort":true,"deepSearch":false,"pageJump":false,"multiSelection":_vm.multiSelection,"searchEnabled":_vm.modal ? true : false},on:{"select":function($event){return _vm.onCommand('select', $event)},"command":function($event){return _vm.onCommand('command', $event)},"loadNextPage":function($event){return _vm.onCommand('loadNextPage', $event)},"multiColumnSort":function($event){return _vm.onCommand('multiColumnSort', $event)},"nItems":function($event){_vm.maxResult = $event}},scopedSlots:_vm._u([{key:"paginationBefore",fn:function(){return [(_vm.tree && !_vm.tree.show)?_c('div',{staticClass:"btn-sidepanel-toggle",class:{ 'pull-left': _vm.pagination }},[_c('div',{staticClass:"btn btn-default hidden-xs",on:{"click":_vm.$refs.fileExplorer.toggle}},[_c('i',{staticClass:"fa fa-list"})])]):_vm._e()]},proxy:true},(_vm.tree)?{key:"id",fn:function(entry){return [_c('div',{staticClass:"jstree-draggable",attrs:{"draggable":"true","data-item-id":entry.item.id,"data-item-name":_vm.screenName(entry.item),"title":`#${entry.item.id} - ${
            entry.item.description || entry.item.name
          }${
            (entry.item.portal_data &&
              entry.item.portal_data.excluded_at &&
              '\n' + _vm.$tc('remove_permanently', 2)) ||
            ''
          }`},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$refs.fileExplorer.dragStart.apply(null, arguments)}}},[_c('i',{class:_vm.draggableIcon}),_c('span',[_vm._v(" "+_vm._s(entry.item.id))])])]}}:null,{key:"name",fn:function(entry){return [_c('span',{class:entry.item.id < 0 ? 'text-danger' : '',attrs:{"title":`#${entry.item.id} - ${
            entry.item.description || entry.item.name
          }${
            (entry.item.portal_data &&
              entry.item.portal_data.excluded_at &&
              '\n' + _vm.$tc('remove_permanently', 2)) ||
            ''
          }`}},[_vm._v(_vm._s(_vm.screenName(entry.item)))]),(entry.item.id < 0)?_c('sup',[_vm._v("*")]):_vm._e()]}},(_vm.items && _vm.items.length && !_vm.maxResult)?{key:"empty",fn:function(){return [_c('div',[_c('div',{staticClass:"alert alert-default"},[_c('div',{staticClass:"h4"},[_vm._v(_vm._s(_vm.$t("no_result_found")))])])])]},proxy:true}:null],null,true)})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }